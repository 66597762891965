import Carousel from 'react-bootstrap/Carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { BsArrowRightShort } from 'react-icons/bs';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { createContext } from 'react';
import BookNow from './BookNow';
import { Helmet } from 'react-helmet';

// Creating context
export const Store = createContext();

function CarouselFadeExample() {
  const [shareData, setShareData] = useState(null);
  const [banner, setBanner] = useState([]);
  const [brands, setBrands] = useState([]);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerResponse = await axios.get('https://admin.autorentmotorcycle.com/api/getBanners');
        setBanner(bannerResponse.data.data);

        const brandsResponse = await axios.get('https://admin.autorentmotorcycle.com/api/getBrands');
        setBrands(brandsResponse.data.data);

        const companyResponse = await axios.get('https://admin.autorentmotorcycle.com/api/getCompanyProfile');
        setCompany(companyResponse.data.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const handleModel = (brand) => {
    setShareData(brand);
  };

  useEffect(() => {
    const handleBookNowClick = () => {
      document.querySelectorAll('.card').forEach(card => card.classList.remove('active'));
      document.querySelector('.book-now').classList.add('active');
    };

    const bookNowElements = document.querySelectorAll('.book-now');
    bookNowElements.forEach(el => el.addEventListener('click', handleBookNowClick));

    return () => {
      bookNowElements.forEach(el => el.removeEventListener('click', handleBookNowClick));
    };
  }, [brands]);



  return (
    <>
      <Helmet>
        <title>Motorcycle rentals in UAE at Autorent - Home</title>
        <meta
          name="description"
          content="Renting a motorcycle? Stop searching! Rent a Motorcycle in Dubai at Top-notch Prices with our Autorent motorcycle. Ride Now!"
        />
        <meta name="keywords" content="Home" />
      </Helmet>

      {/* Carousel */}
      <Carousel indicators={false}>
        {banner.map((item, index) => (
          <Carousel.Item key={index} className="img-wrapper">
            <img
              src={item.large_image_path && item.brand_image ? `${item.large_image_path}${item.brand_image}` : '/path-to-default-image.jpg'}
              alt={item.brand_fist_heading || 'Slide Image'}
              loading="lazy"
            />
            <Carousel.Caption>
              <h3 className="banner-heading-1">{item.brand_fist_heading}</h3>
              <h3 className="banner-heading-2">{item.brand_fist_sub_heading}</h3>
              <p>{item.brand_second_heading}</p>
              <p>{item.brand_second_sub_heading}</p>
              <p
                className="carousel-left-icon-p"
                data-toggle="modal"
                data-target="#exampleModal-1"
                onClick={() => handleModel(item)}
              >
                <span>
                  BOOK NOW <BsArrowRightShort className="carousel-left-icon" />
                </span>
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Brands Section */}
      <Container>
        <Row className="brands" style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={12} style={{ padding: '20px' }}>
            <Row>
              {brands.map((item, index) => {
                const isLastItem = index === brands.length - 1;
                const itemStyle = isLastItem ? { borderRight: 'none' } : { borderRight: '1px solid black' };

                return (
                  <Col key={index}>
                    <Link to={`product/${item.slug}`}>
                      <div className="item" style={itemStyle}>
                        <p className="owl-theme-p">{item.brand_title}</p>
                        <img
                          src={`${item.large_image_path}/${item.brand_model_image}`}
                          alt={item.brand_title}
                          width="100%"
                          height="auto"

                        />
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Company Section */}


      <Container fluid>
        <Row className="mt-5">
          <Col lg={6} xs={6} className="d-flex justify-content-end">
            <h1 className="auto-rent-heading-1">Autorent</h1>
          </Col>
          <Col lg={6} xs={6} className="d-flex justify-content-start">
            <h1 className="auto-rent-heading-2">Advantage</h1>
          </Col>
        </Row>

        <div className="text-center heading-div">
          <h6 className="auto-rent-heading-3">
            We get you where you want to be, fast and safe
          </h6>
        </div>

        <Row className="auto-rent-row">
          <Col className="company-profile" lg={10} md={10}>
            <div className="mt-5">
              <Row>
                <Col lg={6} md={6} sm={9} xs={12} className="p-2">
                  <div>
                    <h1 className="auto-rent-font" style={{ fontWeight: '700', fontSize: '28px' }}>
                      Autorent Motorcycle Rental
                    </h1>
                    <p className="p-text">
                      Autorent Motorcycle Rental LLC is one of the youngest leading motorcycle rental and leasing companies with 500+ successful deliveries and happy customers. With a growing presence in the UAE and plans to expand further in the GCC region, Autorent Motorcycle Rental has been helping its customers to get on to the road faster with truly hassle-free motorcycle rentals and leasing services.
                    </p>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={3} xs={12} className="p-2 about-pulsar-img">
                  <img src="/Asest/bike/3699862_New.jpg" width="100%" height={'100%'} alt="motorcycle" />
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg={10} className="card-col">
            <Row>
              {[{
                imgSrc: '/Asest/Homepage/Motorcycle_ICONS-02.png',
                title: 'On time, every time',
                description: 'All our motorcycles are brand new, safe, durable, and ready to ride for the roads.'
              }, {
                imgSrc: '/Asest/Homepage/bike.png',
                title: 'Hassle-free service',
                description: 'Documentations are taken care of by our Autorent team, which makes the experience extremely fast and efficient.'
              }, {
                imgSrc: '/Asest/Homepage/Motorcycle_ICONS-03.png',
                title: 'Flexi Fares',
                description: 'We ensure that our customers are happy by charging them reasonably for the experience that we provide.'
              }].map((card, index) => (
                <Col lg={4} md={4} key={index} className="p-2">
                  <Card className="home-cards">
                    <div className="text-center mb-3" style={{ minHeight: '60px' }}>
                      <img src={card.imgSrc} alt={`motorcycle icon ${index + 1}`} width="50px" />
                    </div>
                    <div style={{ minHeight: '100px' }}>
                      <h5 className="text-center">{card.title}</h5>
                      <p className="text-center">{card.description}</p>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>


      <Container fluid>
        <Row className="justify-content-center">
          {brands && brands.map((item, index) => {
            const backgroundColor = item.background_color;

            return (
              <Col lg={10} md={12} xs={12} key={index} className="mt-5">
                <Row className="bike-details-col">
                  {/* If index is even */}
                  {index % 2 === 0 ? (
                    <>
                      {/* Left Side - Text */}
                      <Col lg={6} md={7} xs={7} className="d-flex align-items-center">
                        <div>
                          <Row>
                            <Col
                              lg={9} md={9} xs={9}
                              style={{
                                backgroundColor: backgroundColor,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                opacity: 0.9,
                                color: 'white',
                              }}
                            >
                              <div className="bike-info-tages">
                                <span className="heading-one" style={{ fontWeight: '700', paddingRight: '10px' }}>
                                  {item.brand_title}
                                </span>
                                <span style={{ fontWeight: '700' }}>{item.brand_model}</span>
                                <p>{parse(item.description)}</p>
                                <span
                                  data-toggle="modal"
                                  data-target="#exampleModal-1"
                                  onClick={() => handleModel(item)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  BOOK NOW <BsArrowRightShort className="carousel-left-icon" size={'30px'} />
                                </span>
                              </div>
                            </Col>
                            <Col lg={3} md={3} xs={3} className="triangle-left-5" style={{ backgroundColor, opacity: 0.9 }} />
                          </Row>
                        </div>
                      </Col>

                      {/* Right Side - Image */}
                      <Col lg={6} md={5} xs={5}>
                        <img src={`${item.large_image_path}/${item.brand_image}`} alt="Bike" width="100%" />
                      </Col>
                    </>
                  ) : (
                    <>
                      {/* If index is odd */}

                      {/* Left Side - Image */}
                      <Col lg={6} md={5} xs={5}>
                        <img src={`${item.large_image_path}/${item.brand_image}`} alt="Bike Details" width="100%" />
                      </Col>

                      {/* Right Side - Text */}
                      <Col lg={6} md={7} xs={7} className="d-flex align-items-center">
                        <div>
                          <Row>
                            <Col lg={3} md={3} xs={3} className="triangle-right-5" style={{ backgroundColor, opacity: 0.9 }} />
                            <Col
                              lg={9} md={9} xs={9}
                              style={{
                                backgroundColor: backgroundColor,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                opacity: 0.9,
                                color: 'black',
                              }}
                            >
                              <div className="bike-info-tages">
                                <span className="heading-one" style={{ fontWeight: '700', paddingRight: '10px' }}>
                                  {item.brand_title}
                                </span>
                                <span style={{ fontWeight: '700' }}>{item.brand_model}</span>
                                <p>{parse(item.description)}</p>
                                <span
                                  data-toggle="modal"
                                  data-target="#exampleModal-1"
                                  onClick={() => handleModel(item)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  BOOK NOW <BsArrowRightShort className="carousel-left-icon" size={'30px'} />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container fluid className="partners">
        <div className="mb-5 mt-5 text-center">
          <h3 className="g-heading mb-5">OUR PARTNER</h3>
          <div className="card mx-auto" style={{ width: '18rem', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <a href="https://servicemycar.com/uae" className='m-5'>
              <img
                src="/partners/service-my-car.png"
                className="card-img-top"
                alt="Service My Car"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </Container>



      <Container fluid className='home-gallary mb-5 mt-5'>
        <h3 className='text-center g-heading mb-5'>GALLERY</h3>
        <Row >
          <Col lg={12} xs={12} md={12} style={{ padding: '0px' }}>
            <img src='/Asest/Homepage/gallary.jpg' width={'100%'} loading='lazy' className='gallray-long-img' />
          </Col>
        </Row>




      </Container>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default CarouselFadeExample;



















